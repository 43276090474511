import React, { Component } from "react";

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';


import notification from 'antd/lib/notification';
import Queue from './Queue';
import ReactGA from 'react-ga';
import Picker from 'emoji-picker-react';
import { SendOutlined, SmileOutlined, LockOutlined } from '@ant-design/icons';
import { getSocket } from '../../../socket';

import Core from '../../../Core';
import styles from "./index.scss";


export default class ChatBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            emojiPicker: false,
            stickerPicker: false,
            text: '',
        };
        this.form = React.createRef();
        this.queue = React.createRef();
        this.chatInput = React.createRef();
        this.handleFinish = this.handleFinish.bind(this);
        this.onEmojiClick = this.onEmojiClick.bind(this);
        this.toggleEmojiPicker = this.toggleEmojiPicker.bind(this);
        this.toggleStickerPicker = this.toggleStickerPicker.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sendSticker = this.sendSticker.bind(this);
        this.sendMsg = this.sendMsg.bind(this);
    }

    componentDidMount() {
        const that = this;
        if (getSocket()) {
            getSocket().emit("getQueue");
            getSocket().on('newChatMessageAck', (msg) => {
                console.log(msg);
                that.handleNewChatMessageAck(msg);
            });
            getSocket().on('queue', (queue) => {
                that.queue.current.updateChat(queue);
            });
            getSocket().on('addMsg', (msg) => {
                that.queue.current.addToChat(msg);
            });
            getSocket().on('refreshChat', (queue) => {
                that.queue.current.updateChat(queue);
            });

        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.queue.length == 0 && getSocket()) {
            getSocket().emit("getQueue");
        }
    }

    sendMsg(e) {
        if (!this.state.loading) {
            e.preventDefault();
            this.form.current.submit();
        }
    }

    handleFinish(values) {
        this.setState({ loading: true });
        //this.queue.current.newMessage(values.message);
        this.form.current.resetFields();
        this.chatInput.current.focus();
        if (getSocket() != null) {
            getSocket().emit("newChatMessage", {
                hash: this.props.data.hash,
                message: values.message
            });
            this.setState({ voteLoading: false });
        }
    }

    handleNewChatMessageAck(msg) {
        if (msg) {
            this.setState({ loading: false });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Chat`,
                label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
            });
        } else {
            this.setState({ loading: false });
            notification["error"]({
                message: 'Mensagem',
                description: 'Não foi possível enviar a tua mensagem, tente mais tarde.',
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Chat`,
                label: `Unsuccessful`
            });
        }
    }

    sendSticker(e) {
        this.setState({ stickerPicker: false })


        this.setState({ loading: true });
        //this.queue.current.newMessage(values.message);
        this.form.current.resetFields();
        this.chatInput.current.focus();
        if (getSocket() != null) {
            getSocket().emit("newChatMessage", {
                hash: this.props.data.hash,
                message: "<sticker>" + e.target.id + "</sticker>"
            });
            this.setState({ voteLoading: false });
        }

        // const fail = () => {
        //     this.setState({ loading: false });
        //     this.queue.current.updateChat();
        //     notification["error"]({
        //         message: 'Sticker',
        //         description: 'Não foi possível enviar o seu sticker, tente mais tarde.',
        //     });
        //     ReactGA.event({
        //         category: Core.data().config.site_title,
        //         action: `Chat`,
        //         label: `Unsuccessful`
        //     });
        // };
        // Core.apiService("chat/send", {
        //     data: {
        //         hash: this.props.data.hash,
        //         message: "<sticker>" + e.target.id + "</sticker>"
        //     },
        //     onSuccess: (data) => {
        //         if (data.json && data.json.result) {
        //             this.queue.current.updateChat();
        //             this.setState({ loading: false });
        //             ReactGA.event({
        //                 category: Core.data().config.site_title,
        //                 action: `Chat`,
        //                 label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
        //             });
        //         } else {
        //             console.log("Response of alert submit is not JSON!");
        //             fail();
        //         }
        //     },
        //     onFail: (data) => {
        //         console.log("Sticker create failed.", data);
        //         fail();
        //     }
        // });
    }

    onEmojiClick(event, emojiObject) {
        let addEmoji = this.form.current.getFieldValue('message') + '' + emojiObject.emoji;
        this.form.current.setFieldsValue({ message: addEmoji });
    };

    onChange = e => {
        this.form.current.setFieldsValue({ message: e.target.value });
    };

    toggleEmojiPicker() {
        this.setState({ stickerPicker: false })
        this.setState({
            emojiPicker: !this.state.emojiPicker
        })
    }

    toggleStickerPicker() {
        this.setState({ emojiPicker: false })
        this.setState({
            stickerPicker: !this.state.stickerPicker
        })
    }

    render() {
        const { loading, text } = this.state;
        if(this.queue == null) this.queue = React.createRef();
        const stickerList = [
            'best.png',
            'cool.png',
            'wecandoit.png'
        ];

        return (
            <div className="chat-box">
                <div className="chat-box__title">
                    <h3>
                        Chat
                    </h3>
                    <hr />
                </div>
                <Queue ref={this.queue} />
                {Core.data().chat_disabled ?
                    <Row align="middle" className="blocked-input">
                        <Col span={4}>
                            <LockOutlined />
                        </Col>
                        <Col span={20}>
                            <span>De momento não estamos a aceitar novas mensagens</span>
                        </Col>
                    </Row>
                    :
                    <Form initialValues={{ message: "" }} className="newMessage" ref={this.form} layout="vertical" onFinish={this.handleFinish}>
                        <Row>
                            <Col span={2}>
                                <Button className="buttonEmoji" type="default" onClick={this.toggleEmojiPicker} disabled={loading}><SmileOutlined /></Button>
                            </Col>
                            <Col span={15}>
                                <Form.Item className="alertMessage" name="message" rules={[
                                    {
                                        required: true,
                                        message: 'Sem mensagem...'
                                    }
                                ]}
                                >
                                    <Input.TextArea
                                        onPressEnter={this.sendMsg}
                                        autoSize={{ minRows: 1, maxRows: 3 }}
                                        ref={this.chatInput}
                                        onClick={() => { this.setState({ emojiPicker: false, stickerPicker: false }) }}
                                        onChange={this.onChange}
                                        placeholder="mensagem..."
                                    />
                                </Form.Item>
                            </Col>
                            {this.state.emojiPicker && <Picker className="emojiPicker" onEmojiClick={this.onEmojiClick} native={true} />}
                            {stickerList.length > 0 && this.state.stickerPicker && <div className="sticker-picker-react"><Row>
                                {stickerList.map((sticker, i) => {
                                    return (<Col span="8">
                                        <img id={sticker} src={`data/stickers/` + sticker} onClick={this.sendSticker} />
                                    </Col>)
                                })}
                            </Row></div>}
                            <Col span={3} className="stickerButtonWrapper">
                                {stickerList.length > 0 &&
                                    <Button className="buttonSticker" onClick={this.toggleStickerPicker} id="stickerPick" type="default" disabled={loading}>
                                        <svg className="anticon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path d="M20,11.5 L20,7.5 C20,5.56700338 18.4329966,4 16.5,4 L7.5,4 C5.56700338,4 4,5.56700338 4,7.5 L4,16.5 C4,18.4329966 5.56700338,20 7.5,20 L12.5,20 C13.3284271,20 14,19.3284271 14,18.5 L14,16.5 C14,14.5670034 15.5670034,13 17.5,13 L18.5,13 C19.3284271,13 20,12.3284271 20,11.5 Z M19.9266247,13.5532532 C19.522053,13.8348821 19.0303092,14 18.5,14 L17.5,14 C16.1192881,14 15,15.1192881 15,16.5 L15,18.5 C15,18.9222858 14.8952995,19.3201175 14.7104416,19.668952 C17.4490113,18.8255402 19.5186665,16.4560464 19.9266247,13.5532532 L19.9266247,13.5532532 Z M7.5,3 L16.5,3 C18.9852814,3 21,5.01471863 21,7.5 L21,12.5 C21,17.1944204 17.1944204,21 12.5,21 L7.5,21 C5.01471863,21 3,18.9852814 3,16.5 L3,7.5 C3,5.01471863 5.01471863,3 7.5,3 Z" />
                                        </svg>
                                    </Button>}
                            </Col>
                            <Col span={4}>
                                <Button className="buttonSend" onClick={() => { this.setState({ emojiPicker: false, stickerPicker: false }) }} id="chatSend" type="primary" htmlType="submit" disabled={loading}><SendOutlined /></Button>
                            </Col>
                        </Row>
                    </Form>
                }
            </div>
        );
    }
}
